import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";

const AboutTwo = () => {
    return (
        <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/about-1.jpg" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient">Acerca del área.</span></h4>
                                    <h2 className="title mt--10">Lo que podemos hacer.</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>El área de tecnologías de Acresco Soluciones está encargada del desarrollo de sistemas web y aplicaciones móviles, así como la implantación de nuevos procesos tecnológicos que constituyan una herramienta para los colaboradores de la empresa.
                                    </p>

                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> Soporte técnico remoto.</li>
                                        <li><span className="icon"><FiCheck /></span> Diseño de formatos de hoja de cálculo.</li>
                                        <li><span className="icon"><FiCheck /></span> Lectura y explotación de información. </li>
                                        <li><span className="icon"><FiCheck /></span> Resolución de tickets del área de sistemas.</li>
                                    </ul>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                        <Link className="btn-default btn-icon" to="https://integraciones.acrescotec.com">Enviar solicitud <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutTwo;
